import React, { useEffect, useState, useRef, CSSProperties } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { connect } from "react-redux";
import OrderSummary from "./OrderSummary";
import Payment from "../payments/Payment";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { PayPalScriptProvider, PayPalButtons, BraintreePayPalButtons, usePayPalHostedFields, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { PayPalButton } from "react-paypal-button-v2";

const baseURL = "https://mitserver.app:1515";
const override: CSSProperties = {
	display: "block",
	margin: "0 auto",
	borderColor: "red",
};

const style = { layout: "vertical" };

const ButtonWrapper = ({ amount, shipping, userData, products, customer, shippingMethod, handleView, serviceCode, totalWeight }) => {
	const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch({
			type: "resetOptions",
			value: {
				...options,
				currency: "USD",
			},
		});
	}, [amount]);

	async function saveOrder(nonce, orderID, payerID, paymentID) {
		// products = JSON.parse(products);
		const response = await axios.post(baseURL + "/actions", {
			action: "add",
			table: "orders",
			shippingAmount: shipping.toFixed(2),
			totalAmount: amount,
			customer: customer,
			products,
			nonce,
			orderID,
			payerID,
			paymentID,
			data: { id: "9" },
			discount: null,
			taxes: null,
			shippingMethod,
			serviceCode,
		});
		var data = response.data;
		var result = data.result;
		if (result.ok === 1) {
			navigate("/");
			dispatch({ type: "ADD_SHIPPING", payload: 0 });
			localStorage.removeItem("addedItems");
			localStorage.setItem("totalWeight", 0);
			localStorage.setItem("total", 0);
			toast.success(result.text, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	}

	return (
		<BraintreePayPalButtons
			style={style}
			disabled={false}
			// fundingSource="paypal" // Available values are: ["paypal", "card", "credit", "paylater", "venmo"]
			forceReRender={[style, amount]}
			createOrder={function (data, actions) {
				return actions.braintree
					.createPayment({
						flow: "checkout",
						amount: amount, // Here change the amount if needed
						currency: "USD", // Here change the currency if needed
						intent: "capture",
						enableShippingAddress: false,
						shippingAddressEditable: false,
						shippingAddressOverride: {
							recipientName: userData.name + " " + userData.lastName,
							line1: userData.address,
							line2: "",
							city: userData.city,
							countryCode: userData.country,
							postalCode: userData.zip,
							state: userData.state,
							phone: userData.phone,
						},
					})
					.then((orderId) => {
						console.log("ORDER ID", orderId);
						return orderId;
					});
			}}
			onApprove={function (data, actions) {
				return actions.braintree.tokenizePayment(data).then((payload) => {
					var nonce = payload.nonce;
					var orderID = data.orderID;
					var payerID = data.payerID;
					var paymentID = data.paymentID;
					saveOrder(nonce, orderID, payerID, paymentID);
				});
			}}
			onError={function (err) {
				console.log("ERROR", err);
				handleView();
				toast.warning("Please Verify Your City, State or Zip", {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			}}
		/>
	);
};

const CheckoutForm = ({ total, totalWeight, shipping, products, userID }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [data, setData] = useState({});
	const [logged, setLogged] = useState(false);
	const [showUPS, setShowUPS] = useState(false);
	const [showUSPS, setShowUSPS] = useState(false);
	const [amount, setAmount] = useState(0);
	const [shipDescription, setShipDescription] = useState("");
	const [loading, setLoading] = useState(false);
	const [viewShipping, setViewShipping] = useState(false);
	const [newCustomer, setNewCustomer] = useState(false);
	const [clientToken, setClientToken] = useState(null);
	const [shippingMethod, setShippingMethod] = useState();
	const [serviceCode, setServiceCode] = useState();
	const [cardData, setCardData] = useState({
		number: "",
		name: "",
		expiry: "",
		cvc: "",
		focus: "",
	});
	const [errors, setErrors] = useState({});
	const [viewInterShipping, setViewInterShipping] = useState(false);
	const [isBillingSameAsShipping, setIsBillingSameAsShipping] = useState(true);
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(""); // Estado para el método de pago seleccionado

	const handlePaymentMethodChange = (event) => {
		setSelectedPaymentMethod(event.target.value);
	};

	// useEffect(() => {
	// 	(async () => {
	// 		const response = await axios.get(baseURL + "/clientToken");
	// 		var data1 = response.data;
	// 		var result = data1.result;
	// 		var data2 = result.data;
	// 		setClientToken(data2);
	// 		console.log("RESULT TOKEN", result);
	// 	})();
	// }, []);

	useEffect(() => {
		try {
			var userData = localStorage.getItem("userData");
			userData = JSON.parse(userData);
			if (userID !== null) {
				setData(userData);
				setLogged(true);
			} else {
				console.log("USER ID NULL");
			}
		} catch (err) {
			console.log("ERROR", err);
		}
	}, []);

	function handleView() {
		setViewShipping(false);
		setAmount(0);
	}

	async function handleSubmit(event) {
		event.preventDefault();

		if (data.country === "United States") {
			const response = await axios.post(baseURL + "/checkUser", {
				//CHECK CUSTOMER ADDRESS IF VALID
				data,
			});
			var checkData = response.data;
			var result = checkData.result;
			if (result.ok === 1) {
				var data1 = result.data;
				console.log("DATA CHECK USER", data1);
				// if (data.id === undefined) {
				setData({ ...data, id: data1.id });
				// }
				localStorage.setItem("userID", data1.id);
				localStorage.setItem("userData", JSON.stringify(data1));
				setViewShipping(true);
				setViewInterShipping(false);
				if (result.newCustomer === 1) {
					setNewCustomer(true);
				}
				toast.success(result.text, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				// onChangeValue("UPS");
			} else {
				toast.error(result.text, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			}
		} else {
			const response = await axios.post(baseURL + "/checkUserInt", {
				data,
			});
			var checkData = response.data;
			var result = checkData.result;
			if (result.ok === 1) {
				var data1 = result.data;
				localStorage.setItem("userID", data1.id);
				localStorage.setItem("userData", JSON.stringify(data1));
				getIntRateUSPS();
				setLoading(true);
				setViewInterShipping(true);
				setViewShipping(false);
				setShippingMethod("International");

				if (result.newCustomer === 1) {
					setNewCustomer(true);
				}
				toast.success(result.text, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});

				// onChangeValue("UPS EXPRESS");
			} else {
				toast.error(result.text, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			}
		}
	}

	let totalAmount = (total + shipping).toFixed(2);

	function handleOnChange(evt) {
		const value = evt.target.value;
		setData({
			...data,
			[evt.target.name]: value,
		});
	}

	function onChangeValue(event) {
		if (event.target.id === "UPS") {
			setShowUPS(true);
			setShowUSPS(false);
			setAmount("");
			getRateUPS("03");
			setShippingMethod("UPS");
		} else {
			setShowUPS(false);
			setShowUSPS(true);
			setAmount("");
			getRateUSPS("03");
			setShippingMethod("USPS");
		}
	}

	// function onChangeUPS(event) {
	//     setLoading(true);
	//     var code = event.target.id;

	//     if (code === "01") {
	//         setShippingMethod("UPS Next Day Air");
	//     }
	//     if (code === "02") {
	//         setShippingMethod("UPS 2nd Day Air");
	//     }
	//     if (code === "03") {
	//         setShippingMethod("UPS Ground");
	//     }
	//     if (code === "12") {
	//         setShippingMethod("UPS 3rd Day Air");
	//     }
	//     getRate(code);

	// }

	function onChangeUSPS(event) {
		console.log("UPS", event.target);
		// if (event.target.id === 'UPS') {
		//     setShowUPS(true);
		// }
	}

	async function getRateUPS(sCode) {
		if (data.country === "United States") {
			setServiceCode(sCode);
		} else {
			setServiceCode("11");
		}
		const response = await axios.post(baseURL + "/upsGetRates", {
			serviceCode: data.country === "United States" ? sCode : "11",
			totalWeight,
			customerName: data.name,
			customerLastName: data.lastName,
			customerAddress: data.address,
			customerCity: data.city,
			customerState: data.state,
			customerZip: data.zip,
			customerCountry: data.country,
		});
		var data1 = response.data;
		var result = data1.result;
		if (result.ok === 1) {
			setLoading(false);
			var amount = result.data;
			setAmount(amount);
			dispatch({ type: "ADD_SHIPPING", payload: Number(amount) });

			// saveOrder();

			toast.success(result.text, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	}

	async function getRateUSPS(serviceCode) {
		setServiceCode(serviceCode);
		const response = await axios.post(baseURL + "/uspsGetRates", {
			serviceCode,
			totalWeight,
			customerName: data.name,
			customerLastName: data.lastName,
			customerAddress: data.address,
			customerCity: data.city,
			customerState: data.state,
			customerZip: data.zip,
			customerCountry: data.country,
		});
		var data1 = response.data;
		var result = data1.result;
		if (result.ok === 1) {
			setLoading(false);
			var amount = Number(result.amount);
			setAmount(amount);
			setShipDescription(result.serviceType);
			dispatch({ type: "ADD_SHIPPING", payload: Number(amount) });

			// saveOrder();

			toast.success(result.text, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	}

	async function getIntRateUSPS(serviceCode) {
		setServiceCode(serviceCode);
		const response = await axios.post(baseURL + "/uspsIntGetRates", {
			totalWeight,
			country: data.country,
		});
		var data1 = response.data;
		var result = data1.result;
		if (result.ok === 1) {
			setLoading(false);
			var amount = Number(result.amount);
			setAmount(amount);
			setShipDescription(result.serviceType);
			dispatch({ type: "ADD_SHIPPING", payload: Number(amount) });

			toast.success(result.text, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	}

	async function saveOrder(nonce, orderID, payerID, paymentID) {
		const errors = validateCardData();
		if (Object.keys(errors).length === 0) {
			const response = await axios.post(baseURL + "/actions", {
				action: "add",
				table: "orders",
				serviceCode: shipDescription,
				totalWeight,
				shippingAmount: shipping.toFixed(2),
				totalAmount: (total + shipping).toFixed(2),
				customer: data,
				products: JSON.stringify(products),
				// nonce,
				// orderID,
				// payerID,
				// paymentID,
				data: { id: "9" },
				discount: null,
				taxes: null,
				shippingMethod,
				serviceCode,
				cardData,
				notes: data.notes ? data.notes : "",
			});

			var data1 = response.data;
			var result = data1.result;

			if (result.ok === 1) {
				navigate("/");
				dispatch({ type: "ADD_SHIPPING", payload: 0 });
				localStorage.removeItem("addedItems");
				localStorage.setItem("totalWeight", 0);
				localStorage.setItem("total", 0);
				toast.success(result.text, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			}
		} else {
			console.log("Validation errors:", errors);
		}
	}

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setCardData({ ...cardData, [name]: value });
	};

	const handleInputFocus = (e) => {
		setCardData({ ...cardData, focus: e.target.name });
	};

	const validateCardData = () => {
		const errors = {};
		if (!cardData.number) errors.number = "Card number is required";
		if (!cardData.name) errors.name = "Name is required";
		if (!cardData.expiry) errors.expiry = "Required";
		if (!cardData.cvc) errors.cvc = "Required";

		// Add more specific validations as needed
		if (cardData.number && !/^\d{16}$/.test(cardData.number)) {
			errors.number = "Invalid card number";
		}
		if (cardData.expiry && !/^\d{2}\/\d{2}$/.test(cardData.expiry)) {
			errors.expiry = "Invalid Format (MM/YY)";
		}
		if (cardData.cvc && !/^\d{3,4}$/.test(cardData.cvc)) {
			errors.cvc = "Invalid CVC";
		}
		setErrors(errors);

		return errors;
	};

	const handleCheckboxChange = (event) => {
		setIsBillingSameAsShipping(event.target.checked);
	};

	return (
		<section className="checkout-area ptb-60">
			<div className="container">
				<div className="row">
					{!logged && (
						<div className="col-lg-12 col-md-12">
							<div className="user-actions">
								<i className="fas fa-sign-in-alt"></i>
								<span>
									Returning customer? <Link to="/login">Click here to login</Link>
								</span>
							</div>
						</div>
					)}
				</div>

				<form onSubmit={handleSubmit}>
					<div className="row">
						<div className="col-lg-6 col-md-12">
							<div className="billing-details">
								<h3 className="title">Shipping Details</h3>

								<div className="row">
									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>
												First Name <span className="required">*</span>
											</label>
											<input
												id="name"
												type="text"
												name="name"
												className="form-control"
												onChange={(event) => handleOnChange(event)}
												value={data.name}
												required
											/>
											{/* {state.name.error && <p style={errorStyle}>{state.name.error}</p>} */}
										</div>
									</div>

									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>
												Last Name <span className="required">*</span>
											</label>
											<input
												type="text"
												name="lastName"
												className="form-control"
												onChange={(event) => handleOnChange(event)}
												value={data.lastName}
												required
											/>
											{/* {state.lastName.error && <p style={errorStyle}>{state.lastName.error}</p>} */}
										</div>
									</div>

									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>
												Email Address <span className="required">*</span>
											</label>
											<input
												type="email"
												name="email"
												className="form-control"
												onChange={(event) => handleOnChange(event)}
												value={data.email}
												required
											/>
											{/* {state.email.error && <p style={errorStyle}>{state.email.error}</p>} */}
										</div>
									</div>

									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>
												Phone <span className="required">*</span>
											</label>
											<input
												type="text"
												name="phone"
												className="form-control"
												onChange={(event) => handleOnChange(event)}
												value={data.phone}
												required
											/>
											{/* {state.phone.error && <p style={errorStyle}>{state.phone.error}</p>} */}
										</div>
									</div>

									<div className="col-lg-12 col-md-12">
										<div className="form-group">
											<label>Company Name</label>
											<input
												type="text"
												name="companyName"
												className="form-control"
												onChange={(event) => handleOnChange(event)}
												value={data.companyName}
											/>
										</div>
									</div>

									<div className="col-lg-12 col-md-12">
										<div className="form-group">
											<label>
												Country <span className="required">*</span>
											</label>
											<div className="select-box">
												<select
													className="form-control"
													name="country"
													value={data.country}
													onChange={(event) => handleOnChange(event)}
													required>
													<option value="">-- Select Country --</option>
													<option value="Afghanistan">Afghanistan</option>
													<option value="Åland Islands">Åland Islands</option>
													<option value="Albania">Albania</option>
													<option value="Algeria">Algeria</option>
													<option value="American Samoa">American Samoa</option>
													<option value="Andorra">Andorra</option>
													<option value="Angola">Angola</option>
													<option value="Anguilla">Anguilla</option>
													<option value="Antarctica">Antarctica</option>
													<option value="Antigua and Barbuda">Antigua and Barbuda</option>
													<option value="Argentina">Argentina</option>
													<option value="Armenia">Armenia</option>
													<option value="Aruba">Aruba</option>
													<option value="Australia">Australia</option>
													<option value="Austria">Austria</option>
													<option value="Azerbaijan">Azerbaijan</option>
													<option value="Bahamas">Bahamas</option>
													<option value="Bahrain">Bahrain</option>
													<option value="Bangladesh">Bangladesh</option>
													<option value="Barbados">Barbados</option>
													<option value="Belarus">Belarus</option>
													<option value="Belgium">Belgium</option>
													<option value="Belize">Belize</option>
													<option value="Benin">Benin</option>
													<option value="Bermuda">Bermuda</option>
													<option value="Bhutan">Bhutan</option>
													<option value="Bolivia, Plurinational State of">Bolivia, Plurinational State of</option>
													<option value="Bonaire, Sint Eustatius and Saba">Bonaire, Sint Eustatius and Saba</option>
													<option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
													<option value="Botswana">Botswana</option>
													<option value="Bouvet Island">Bouvet Island</option>
													<option value="Brazil">Brazil</option>
													<option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
													<option value="Brunei Darussalam">Brunei Darussalam</option>
													<option value="Bulgaria">Bulgaria</option>
													<option value="Burkina Faso">Burkina Faso</option>
													<option value="Burundi">Burundi</option>
													<option value="Cambodia">Cambodia</option>
													<option value="Cameroon">Cameroon</option>
													<option value="Canada">Canada</option>
													<option value="Cape Verde">Cape Verde</option>
													<option value="Cayman Islands">Cayman Islands</option>
													<option value="Central African Republic">Central African Republic</option>
													<option value="Chad">Chad</option>
													<option value="Chile">Chile</option>
													<option value="China">China</option>
													<option value="Christmas Island">Christmas Island</option>
													<option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
													<option value="Colombia">Colombia</option>
													<option value="Comoros">Comoros</option>
													<option value="Congo">Congo</option>
													<option value="Congo, the Democratic Republic of the">
														Congo, the Democratic Republic of the
													</option>
													<option value="Cook Islands">Cook Islands</option>
													<option value="Costa Rica">Costa Rica</option>
													<option value="Côte d'Ivoire">Côte d'Ivoire</option>
													<option value="Croatia">Croatia</option>
													<option value="Cuba">Cuba</option>
													<option value="Curaçao">Curaçao</option>
													<option value="Cyprus">Cyprus</option>
													<option value="Czech Republic">Czech Republic</option>
													<option value="Denmark">Denmark</option>
													<option value="Djibouti">Djibouti</option>
													<option value="Dominica">Dominica</option>
													<option value="Dominican Republic">Dominican Republic</option>
													<option value="Ecuador">Ecuador</option>
													<option value="Egypt">Egypt</option>
													<option value="El Salvador">El Salvador</option>
													<option value="Equatorial Guinea">Equatorial Guinea</option>
													<option value="Eritrea">Eritrea</option>
													<option value="Estonia">Estonia</option>
													<option value="Ethiopia">Ethiopia</option>
													<option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
													<option value="Faroe Islands">Faroe Islands</option>
													<option value="Fiji">Fiji</option>
													<option value="Finland">Finland</option>
													<option value="France">France</option>
													<option value="French Guiana">French Guiana</option>
													<option value="French Polynesia">French Polynesia</option>
													<option value="French Southern Territories">French Southern Territories</option>
													<option value="Gabon">Gabon</option>
													<option value="Gambia">Gambia</option>
													<option value="Georgia">Georgia</option>
													<option value="Germany">Germany</option>
													<option value="Ghana">Ghana</option>
													<option value="Gibraltar">Gibraltar</option>
													<option value="Greece">Greece</option>
													<option value="Greenland">Greenland</option>
													<option value="Grenada">Grenada</option>
													<option value="Guadeloupe">Guadeloupe</option>
													<option value="Guam">Guam</option>
													<option value="Guatemala">Guatemala</option>
													<option value="Guernsey">Guernsey</option>
													<option value="Guinea">Guinea</option>
													<option value="Guinea-Bissau">Guinea-Bissau</option>
													<option value="Guyana">Guyana</option>
													<option value="Haiti">Haiti</option>
													<option value="Heard Island and McDonald Islands">Heard Island and McDonald Islands</option>
													<option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
													<option value="Honduras">Honduras</option>
													<option value="Hong Kong">Hong Kong</option>
													<option value="Hungary">Hungary</option>
													<option value="Iceland">Iceland</option>
													<option value="India">India</option>
													<option value="Indonesia">Indonesia</option>
													<option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
													<option value="Iraq">Iraq</option>
													<option value="Ireland">Ireland</option>
													<option value="Isle of Man">Isle of Man</option>
													<option value="Israel">Israel</option>
													<option value="Italy">Italy</option>
													<option value="Jamaica">Jamaica</option>
													<option value="Japan">Japan</option>
													<option value="Jersey">Jersey</option>
													<option value="Jordan">Jordan</option>
													<option value="Kazakhstan">Kazakhstan</option>
													<option value="Kenya">Kenya</option>
													<option value="Kiribati">Kiribati</option>
													<option value="Korea, Democratic People's Republic of">
														Korea, Democratic People's Republic of
													</option>
													<option value="Korea, Republic of">Korea, Republic of</option>
													<option value="Kuwait">Kuwait</option>
													<option value="Kyrgyzstan">Kyrgyzstan</option>
													<option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
													<option value="Latvia">Latvia</option>
													<option value="Lebanon">Lebanon</option>
													<option value="Lesotho">Lesotho</option>
													<option value="Liberia">Liberia</option>
													<option value="Libya">Libya</option>
													<option value="Liechtenstein">Liechtenstein</option>
													<option value="Lithuania">Lithuania</option>
													<option value="Luxembourg">Luxembourg</option>
													<option value="Macao">Macao</option>
													<option value="Macedonia, the former Yugoslav Republic of">
														Macedonia, the former Yugoslav Republic of
													</option>
													<option value="Madagascar">Madagascar</option>
													<option value="Malawi">Malawi</option>
													<option value="Malaysia">Malaysia</option>
													<option value="Maldives">Maldives</option>
													<option value="Mali">Mali</option>
													<option value="Malta">Malta</option>
													<option value="Marshall Islands">Marshall Islands</option>
													<option value="Martinique">Martinique</option>
													<option value="Mauritania">Mauritania</option>
													<option value="Mauritius">Mauritius</option>
													<option value="Mayotte">Mayotte</option>
													<option value="Mexico">Mexico</option>
													<option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
													<option value="Moldova, Republic of">Moldova, Republic of</option>
													<option value="Monaco">Monaco</option>
													<option value="Mongolia">Mongolia</option>
													<option value="Montenegro">Montenegro</option>
													<option value="Montserrat">Montserrat</option>
													<option value="Morocco">Morocco</option>
													<option value="Mozambique">Mozambique</option>
													<option value="Myanmar">Myanmar</option>
													<option value="Namibia">Namibia</option>
													<option value="Nauru">Nauru</option>
													<option value="Nepal">Nepal</option>
													<option value="Netherlands">Netherlands</option>
													<option value="New Caledonia">New Caledonia</option>
													<option value="New Zealand">New Zealand</option>
													<option value="Nicaragua">Nicaragua</option>
													<option value="Niger">Niger</option>
													<option value="Nigeria">Nigeria</option>
													<option value="Niue">Niue</option>
													<option value="Norfolk Island">Norfolk Island</option>
													<option value="Northern Mariana Islands">Northern Mariana Islands</option>
													<option value="Norway">Norway</option>
													<option value="Oman">Oman</option>
													<option value="Pakistan">Pakistan</option>
													<option value="Palau">Palau</option>
													<option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
													<option value="Panama">Panama</option>
													<option value="Papua New Guinea">Papua New Guinea</option>
													<option value="Paraguay">Paraguay</option>
													<option value="Peru">Peru</option>
													<option value="Philippines">Philippines</option>
													<option value="Pitcairn">Pitcairn</option>
													<option value="Poland">Poland</option>
													<option value="Portugal">Portugal</option>
													<option value="Puerto Rico">Puerto Rico</option>
													<option value="Qatar">Qatar</option>
													<option value="Réunion">Réunion</option>
													<option value="Romania">Romania</option>
													<option value="Russian Federation">Russian Federation</option>
													<option value="Rwanda">Rwanda</option>
													<option value="Saint Barthélemy">Saint Barthélemy</option>
													<option value="Saint Helena, Ascension and Tristan da Cunha">
														Saint Helena, Ascension and Tristan da Cunha
													</option>
													<option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
													<option value="Saint Lucia">Saint Lucia</option>
													<option value="Saint Martin (French part)">Saint Martin (French part)</option>
													<option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
													<option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
													<option value="Samoa">Samoa</option>
													<option value="San Marino">San Marino</option>
													<option value="Sao Tome and Principe">Sao Tome and Principe</option>
													<option value="Saudi Arabia">Saudi Arabia</option>
													<option value="Senegal">Senegal</option>
													<option value="Serbia">Serbia</option>
													<option value="Seychelles">Seychelles</option>
													<option value="Sierra Leone">Sierra Leone</option>
													<option value="Singapore">Singapore</option>
													<option value="Sint Maarten (Dutch part)">Sint Maarten (Dutch part)</option>
													<option value="Slovakia">Slovakia</option>
													<option value="Slovenia">Slovenia</option>
													<option value="Solomon Islands">Solomon Islands</option>
													<option value="Somalia">Somalia</option>
													<option value="South Africa">South Africa</option>
													<option value="South Georgia and the South Sandwich Islands">
														South Georgia and the South Sandwich Islands
													</option>
													<option value="South Sudan">South Sudan</option>
													<option value="Spain">Spain</option>
													<option value="Sri Lanka">Sri Lanka</option>
													<option value="Sudan">Sudan</option>
													<option value="Suriname">Suriname</option>
													<option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
													<option value="Swaziland">Swaziland</option>
													<option value="Sweden">Sweden</option>
													<option value="Switzerland">Switzerland</option>
													<option value="Syrian Arab Republic">Syrian Arab Republic</option>
													<option value="Taiwan, Province of China">Taiwan, Province of China</option>
													<option value="Tajikistan">Tajikistan</option>
													<option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
													<option value="Thailand">Thailand</option>
													<option value="Timor-Leste">Timor-Leste</option>
													<option value="Togo">Togo</option>
													<option value="Tokelau">Tokelau</option>
													<option value="Tonga">Tonga</option>
													<option value="Trinidad and Tobago">Trinidad and Tobago</option>
													<option value="Tunisia">Tunisia</option>
													<option value="Turkey">Turkey</option>
													<option value="Turkmenistan">Turkmenistan</option>
													<option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
													<option value="Tuvalu">Tuvalu</option>
													<option value="Uganda">Uganda</option>
													<option value="Ukraine">Ukraine</option>
													<option value="United Arab Emirates">United Arab Emirates</option>
													<option value="United Kingdom">United Kingdom</option>
													<option value="United States">United States</option>
													<option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
													<option value="Uruguay">Uruguay</option>
													<option value="Uzbekistan">Uzbekistan</option>
													<option value="Vanuatu">Vanuatu</option>
													<option value="Venezuela, Bolivarian Republic of">Venezuela, Bolivarian Republic of</option>
													<option value="Viet Nam">Viet Nam</option>
													<option value="Virgin Islands, British">Virgin Islands, British</option>
													<option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
													<option value="Wallis and Futuna">Wallis and Futuna</option>
													<option value="Western Sahara">Western Sahara</option>
													<option value="Yemen">Yemen</option>
													<option value="Zambia">Zambia</option>
													<option value="Zimbabwe">Zimbabwe</option>
												</select>
											</div>
										</div>
									</div>

									<div className="col-lg-12 col-md-6">
										<div className="form-group">
											<label>
												Address <span className="required">*</span>
											</label>
											<input
												type="text"
												name="address"
												className="form-control"
												onChange={(event) => handleOnChange(event)}
												value={data.address}
												required
											/>
											{/* {state.address.error && <p style={errorStyle}>{state.address.error}</p>} */}
										</div>
									</div>

									<div className="col-lg-12 col-md-6">
										<div className="form-group">
											<label>
												Town / City <span className="required">*</span>
											</label>
											<input
												type="text"
												name="city"
												className="form-control"
												onChange={(event) => handleOnChange(event)}
												value={data.city}
												required
											/>
											{/* {state.city.error && <p style={errorStyle}>{state.city.error}</p>} */}
										</div>
									</div>
									{data.country === "United States" && (
										<div className="col-lg-6 col-md-6">
											<div className="form-group">
												<label>
													State / County <span className="required">*</span>
												</label>
												<div className="select-box">
													<select
														className="form-control"
														name="state"
														value={data.state}
														onChange={(event) => handleOnChange(event)}
														required>
														<option value="NO">-- Select State --</option>
														<option value="AL">Alabama</option>
														<option value="AK">Alaska</option>
														<option value="AZ">Arizona</option>
														<option value="AR">Arkansas</option>
														<option value="CA">California</option>
														<option value="CO">Colorado</option>
														<option value="CT">Connecticut</option>
														<option value="DE">Delaware</option>
														<option value="FL">Florida</option>
														<option value="GA">Georgia</option>
														<option value="HI">Hawaii</option>
														<option value="ID">Idaho</option>
														<option value="IL">Illinois</option>
														<option value="IN">Indiana</option>
														<option value="IA">Iowa</option>
														<option value="KS">Kansas</option>
														<option value="KY">Kentucky</option>
														<option value="LA">Louisiana</option>
														<option value="ME">Maine</option>
														<option value="MD">Maryland</option>
														<option value="MA">Massachusetts</option>
														<option value="MI">Michigan</option>
														<option value="MN">Minnesota</option>
														<option value="MS">Mississippi</option>
														<option value="MO">Missouri</option>
														<option value="MT">Montana</option>
														<option value="NE">Nebraska</option>
														<option value="NV">Nevada</option>
														<option value="NH">New Hampshire</option>
														<option value="NJ">New Jersey</option>
														<option value="NM">New Mexico</option>
														<option value="NY">New York</option>
														<option value="NC">North Carolina</option>
														<option value="ND">North Dakota</option>
														<option value="OH">Ohio</option>
														<option value="OK">Oklahoma</option>
														<option value="OR">Oregon</option>
														<option value="PA">Pennsylvania</option>
														<option value="RI">Rhode Island</option>
														<option value="SC">South Carolina</option>
														<option value="SD">South Dakota</option>
														<option value="TN">Tennessee</option>
														<option value="TX">Texas</option>
														<option value="UT">Utah</option>
														<option value="VT">Vermont</option>
														<option value="VA">Virginia</option>
														<option value="WA">Washington</option>
														<option value="WV">West Virginia</option>
														<option value="WI">Wisconsin</option>
														<option value="WY">Wyoming</option>
													</select>
												</div>
												{/* {state.state.error && <p style={errorStyle}>{state.state.error}</p>} */}
											</div>
										</div>
									)}

									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>
												Postcode / Zip <span className="required">*</span>
											</label>
											<input
												type="text"
												name="zip"
												className="form-control"
												onChange={(event) => handleOnChange(event)}
												value={data.zip}
												required
											/>
											{/* {state.zip.error && <p style={errorStyle}>{state.zip.error}</p>} */}
										</div>
									</div>

									<div className="col-lg-6 col-md-6">
										<div className="form-group">
											<label>
												<input
													type="checkbox"
													name="billingAddress"
													onChange={handleCheckboxChange}
													checked={isBillingSameAsShipping}
												/>
												{"  "}Billing address same as shipping
											</label>
										</div>
										{/* Mostrar leyenda si el checkbox está desmarcado */}
										{!isBillingSameAsShipping && (
											<div style={{ color: "blue", marginTop: "10px" }}>Please write the billing address in notes.</div>
										)}
									</div>
									<div className="col-lg-12 col-md-12">
										<div className="form-check">
											{/* <input type="checkbox" className="form-check-input" id="create-an-account" />
                                            <label className="form-check-label" htmlFor="create-an-account">Create an account?</label> */}
										</div>
									</div>

									{/* <div className="col-lg-12 col-md-12">
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="ship-different-address" />
                                            <label className="form-check-label" htmlFor="ship-different-address">Ship to a different address?</label>
                                        </div>
                                    </div> */}

									<div className="col-lg-12 col-md-12">
										<div className="form-group">
											<textarea
												name="notes"
												onChange={(event) => handleOnChange(event)}
												id="notes"
												cols="30"
												rows="6"
												placeholder="Order Notes"
												className="form-control"
											/>
										</div>
									</div>
									{userID === null ? (
										<input
											type="submit"
											value="Create Account to Show Shipping Options"
											style={{ backgroundColor: viewShipping ? "#90ee90" : "" }}
										/>
									) : (
										<input
											type="submit"
											value="Click to Show Shipping Options"
											style={{ backgroundColor: viewShipping ? "#90ee90" : "" }}
										/>
									)}
									{newCustomer && (
										<div className="payment-method text-center">
											<h4 className="title">A message with your password was sent to your email</h4>
										</div>
									)}
								</div>
							</div>
						</div>

						<div className="col-lg-6 col-md-12">
							<div className="order-details">
								<h3 className="title">Your Order</h3>

								<OrderSummary />

								{viewShipping && (
									<div className="payment-method">
										<h3 className="title">Shipping Methods</h3>

										<div onChange={onChangeValue} className="d-flex justify-content-around">
											<p>
												<input type="radio" id="UPS" name="radio-group" />
												<label htmlFor="UPS">UPS</label>
											</p>

											<p>
												<input type="radio" id="USPS" name="radio-group" />
												<label htmlFor="USPS">USPS</label>
											</p>
										</div>
										{showUPS && (
											<div>
												{/* <div onChange={onChangeUPS} className="d-flex justify-content-around">

                                                    <input type="radio" id="03" name="ups-group" />
                                                    <label htmlFor="UPS Ground">UPS Ground</label>


                                                    <input type="radio" id="01" name="ups-group" />
                                                    <label htmlFor="UPS Next Day Air">UPS Next Day Air</label>


                                                    <input type="radio" id="02" name="ups-group" />
                                                    <label htmlFor="UPS 2nd Day Air">UPS 2nd Day Air</label>


                                                    <input type="radio" id="12" name="ups-group" />
                                                    <label htmlFor="UPS 3 Day Air">UPS 3 Day Air</label>

                                                </div> */}
												<div className="d-flex justify-content-center mt-3">
													{loading ? (
														<PropagateLoader
															color={"grey"}
															loading={loading}
															cssOverride={override}
															size={12}
															aria-label="Loading Spinner"
															data-testid="loader"
														/>
													) : (
														<div>
															<div className="title">Amount: {amount}</div>
															<div className="title">Type: UPS Ground</div>
														</div>
													)}
												</div>
											</div>
										)}
										{showUSPS && (
											<div>
												{/* <div onChange={onChangeUSPS} className="d-flex justify-content-around">

                                                    <input type="radio" id="03" name="usps-group" />
                                                    <label htmlFor="USPS Ground">USPS Ground</label>


                                                    <input type="radio" id="01" name="usps-group" />
                                                    <label htmlFor="USPS Next Day Air">USPS Next Day Air</label>


                                                    <input type="radio" id="02" name="usps-group" />
                                                    <label htmlFor="USPS 2nd Day Air">USPS 2nd Day Air</label>


                                                    <input type="radio" id="12" name="usps-group" />
                                                    <label htmlFor="USPS 3 Day Air">USPS 3 Day Air</label>

                                                </div>
                                                <div className="d-flex justify-content-center mt-3">
                                                    <div className="title">Amount: {amount}</div>
                                                </div> 
                                                */}

												<div className="d-flex justify-content-center mt-3">
													{loading ? (
														<PropagateLoader
															color={"grey"}
															loading={loading}
															cssOverride={override}
															size={12}
															aria-label="Loading Spinner"
															data-testid="loader"
														/>
													) : (
														<div>
															<div className="title">Amount: {amount}</div>
															<div className="title">Type: {shipDescription}</div>
														</div>
													)}
												</div>
											</div>
										)}
									</div>
								)}

								{viewInterShipping && (
									<div className="payment-method">
										<h3 className="title">International Shipping Cost : ${amount}</h3>
										<h3 className="title">International Shipping Type : {shipDescription}</h3>
									</div>
								)}

								{amount > 0 && (
									<div className="payment-method">
										<h3 className="title">Payment Methods</h3>
										<div className="d-flex justify-content-around mb-2">
											<label>
												<input
													type="radio"
													value="card"
													checked={selectedPaymentMethod === "card"}
													onChange={handlePaymentMethodChange}
												/>
												<img src="/assets/creditCardLogos.png" alt="Credit Card" style={{ width: "50px" }} />
											</label>
											<label>
												<input
													type="radio"
													value="paypal"
													checked={selectedPaymentMethod === "paypal"}
													onChange={handlePaymentMethodChange}
												/>
												<img src="/assets/paypalLogo.png" alt="PayPal" style={{ width: "50px" }} />
											</label>
										</div>

										{selectedPaymentMethod === "card" && (
											<div className="credit-card-form">
												<div className="credit-card-form">
													<div className="card-display">
														<Cards
															number={cardData.number}
															name={cardData.name}
															expiry={cardData.expiry}
															cvc={cardData.cvc}
															focused={cardData.focus}
														/>
													</div>
													<div className="card-inputs">
														<input
															type="tel"
															name="number"
															placeholder="Card Number"
															value={cardData.number}
															onChange={handleInputChange}
															onFocus={handleInputFocus}
														/>
														{errors.number && <span className="error">{errors.number}</span>}

														<input
															type="text"
															name="name"
															placeholder="Name"
															value={cardData.name}
															onChange={handleInputChange}
															onFocus={handleInputFocus}
														/>
														{errors.name && <span className="error">{errors.name}</span>}

														<div className="expiry-cvc-inputs">
															<input
																type="text"
																name="expiry"
																placeholder="MM/YY Expiry"
																value={cardData.expiry}
																onChange={handleInputChange}
																onFocus={handleInputFocus}
															/>
															{errors.expiry && <span className="error">{errors.expiry}</span>}

															<input
																type="tel"
																name="cvc"
																placeholder="CVC"
																value={cardData.cvc}
																onChange={handleInputChange}
																onFocus={handleInputFocus}
															/>
															{errors.cvc && <span className="error">{errors.cvc}</span>}
														</div>
													</div>
												</div>
											</div>
										)}

										{selectedPaymentMethod === "paypal" && (
											<div className="paypal-button-container">
												<PayPalButton
													amount={1}
													onSuccess={(details, data) => {
														alert("Transaction completed by " + details.payer.name.given_name);
														saveOrder();
													}}
													options={{
														clientId: "Aa1k02yCU0Vfubn_NDqOYQIwibF0h8WQyVEvaCGLhdlHsV77XkDGdocOpIwCeotGhnBUA6fcTcuJOyaf",
													}}
												/>
											</div>
										)}
									</div>
								)}
							</div>
						</div>
					</div>
				</form>
				{amount > 0 && (
					<div className="payment-button">
						<button className="btn btn-primary" onClick={saveOrder}>
							Pay Now
						</button>
					</div>
				)}
			</div>
		</section>
	);
};

const mapStateToProps = (state) => {
	var total = localStorage.getItem("total");
	total = JSON.parse(total);
	var totalWeight = localStorage.getItem("totalWeight");
	var products = localStorage.getItem("addedItems");
	products = JSON.parse(products);
	var userID = localStorage.getItem("userID");

	return {
		total,
		totalWeight,
		shipping: state.shipping,
		products,
		userID,
	};
};

export default connect(mapStateToProps)(CheckoutForm);
